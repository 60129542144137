import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Helmet from "react-helmet"

import { Col, Row } from "antd"
import "antd/dist/antd.css"
import * as styles from "./index.module.scss"

import { Ipage } from "@src/pages"

function Banner() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "about_us/banner.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
      banner_mobile: file(relativePath: { eq: "about_us/banner_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  const image = getImage(data.banner.childImageSharp.gatsbyImageData)
  const image_mobile = getImage(
    data.banner_mobile.childImageSharp.gatsbyImageData
  )
  return (
    <div className={styles.banner}>
      <Row>
        <Col md={24} sm={0} xs={0}>
          {image && <GatsbyImage image={image} alt="banner" />}
        </Col>
        <Col md={0} sm={24} xs={24}>
          {image_mobile && <GatsbyImage image={image_mobile} alt="banner" />}
        </Col>
      </Row>
      <h1>保持创业初心，客户价值第一</h1>
    </div>
  )
}
interface Ititle {
  children: string
  style?: any
}
function Title({ children }: Ititle) {
  return <div className={styles.title}>{children}</div>
}

function Text({ children, style }: Ititle) {
  return (
    <div className={styles.text} style={{ ...style }}>
      {children}
    </div>
  )
}

interface IrenderItem {
  time: string | number
  title: string
  details: string[]
}
function RenderItem({ time, title, details }: IrenderItem) {
  return (
    <div className={styles.flex_item}>
      <div className={styles.time}>{time}</div>
      <div className={styles.intro}>{title}</div>
      <ul className={styles.details}>
        {details.map(item => (
          <li className={styles.detail} key={item}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}

const renderData = [
  {
    time: "2019",
    title: "国内营销企业服务",
    details: ["腾讯广告代理商", "百度、搜狗、新浪等广告代理商"],
  },
  {
    time: "2021",
    title: "企业海外营销",
    details: ["Bing中国区代理商", "Yandex中国区代理商", "Google优秀合作伙伴"],
  },
  {
    time: "2022",
    title: "数字化&智能营销",
    details: ["程序化创意系统", " AI智能投放系统", "大数据选品系统"],
  },
  {
    time: "2023",
    title: "一站式出海营销服务",
    details: ["培训学院", "供应链生态"],
  },
]

export default function AboutUs(params: Ipage) {
  return (
    <div className={styles.about_us}>
      <Helmet title="关于我们-Zero Miracle" />
      <Banner />
      <div className={styles.container}>
        <Title>关于Zero Miracle</Title>
        <div className={styles.text_container}>
          <Text>
            Zero
            Miracle公司成立于2019年，凭借过硬实力与敏锐嗅觉，不到两年，便从国内头部互联网企业（腾讯、百度、新浪、优酷等）广告代理商外拓到跨境业务，布局企业海外营销输出。2021年已是Google核心合作伙伴，后又与Bing、Yahoo、Yandex、LinkedIn等全球知名互联网公司达成深度合作。
          </Text>
          <Text>
            2022年起，Zero
            Miracle将高度前瞻战略目光聚焦于全新赛道，成立技术研发团队，以前沿创新科技为支撑，开发数字化营销和AI智能营销，驱动建设程序化创意系统、大数据选品系统、智能投放广告系统等多元服务工具，超前洞察客户需求，赋能品牌出海大捷。
          </Text>
          <Text>
            经过数年极速发展、优化成长，Zero
            Miracle成功转型为经验丰富、卓绝进取的服务商平台，开拓创新。在发展壮大之时，Zero
            Miracle不忘秉承企业初心，集成供应链系统，打造智库学院，多维度、全方位为中国跨境电商企业提供更胜一筹的一站式独立站营销解决方案
          </Text>
        </div>
      </div>
      <div style={{ background: "#F9FBFE" }}>
        <div className={styles.development}>
          <Title>Zero Miracle发展历程</Title>
          <Text style={{ textAlign: "center", marginTop: "20px" }}>
            有志者，事竟成。在助力品牌出海的航路上，我们永不止步、始终探索、一往无前。
          </Text>
          <div className={styles.flex_box}>
            {renderData.map(item => {
              return <RenderItem {...item} key={item.time} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
