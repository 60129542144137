// extracted by mini-css-extract-plugin
export var about_us = "index-module--about_us--709c5";
export var banner = "index-module--banner--d1707";
export var container = "index-module--container--ea4e2";
export var detail = "index-module--detail--5a8cb";
export var details = "index-module--details--4bc41";
export var development = "index-module--development--bc7b1";
export var flex_box = "index-module--flex_box--e34fd";
export var flex_item = "index-module--flex_item--2373d";
export var intro = "index-module--intro--9ea89";
export var text = "index-module--text--3a247";
export var text_container = "index-module--text_container--96c5d";
export var time = "index-module--time--c03b2";
export var title = "index-module--title--b8b82";